.table-display {
    font-family: Arial, sans-serif;
    margin: 20px 0;
    overflow-x: auto;
    
}

.table-display h4 {
    text-align: center;
    margin-bottom: 20px;
}

.table-display table {
    width: 100%;
    border-collapse: collapse;
    background-color: #f7f9fc;
}

.table-display th, .table-display td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.table-display th {
    background-color: #4CAF50;
    color: white;
}

.table-display tr:nth-child(even) {
    background-color: #f2f2f2;
}

.table-display tr:hover {
    background-color: #ddd;
}

.table-display a {
    color: #1a73e8;
    text-decoration: none;
 
}

.table-display a:hover {
    text-decoration: underline;
}

.table-container {
    overflow-x: auto;
}

button{
    cursor: pointer;
}