/* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  font-family: "Arial", sans-serif;
  background-color: #f4f6f8;
}

/* New Admin Panel */
.new-admin-panel {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Header */
.new-admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 60px;
  background-color: #1e293b;
  color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.header-left {
  display: flex;
  align-items: center;
}

.menu-toggle {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  margin-right: 10px;
}

.header-logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.admin-profile {
  display: flex;
  align-items: center;
}

.profile-pic {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

/* Layout */
.new-admin-layout {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.sidebar {
  width: 250px;
  background-color: #1e293b;
  color: white;
  padding-top: 20px;
  transition: width 0.3s ease;
}

.sidebar.collapsed {
  width: 70px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  padding: 15px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar li:hover {
  background-color: #334155;
}

.sidebar li.active {
  background-color: #4f46e5;
  font-weight: bold;
}

.admin-content {
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  margin: 20px;
  overflow-y: auto;
}

/* Collapsible Sidebar Animations */
.sidebar.collapsed li {
  text-align: center;
}


/* Admin Profile and Dropdown */
.admin-profile {
  position: relative;
  cursor: pointer;
}

.profile-pic {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  transition: transform 0.3s ease;
}

.profile-pic:hover {
  transform: scale(1.1);
}

.dropdown-menu {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.dropdown-item {
  padding: 10px 20px;
  color: #333;
  text-align: left;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background 0.3s ease;
}

.dropdown-item:hover {
  background-color: #f4f4f4;
}

/* Sidebar Logout Styling */
.logout-sidebar {
  color: red;
  font-weight: bold;
  cursor: pointer;
}

.logout-sidebar:hover {
  background-color: rgba(255, 0, 0, 0.1);
}
