/* General Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  color: #333;
}

/* Admin Login Container */

.admin-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #007bff, #6c63ff);
  padding: 0 20px;
}


/* Header Styles */
.admin-header {
  margin-bottom: 40px;
  text-align: center;
}


.admin-header h1 {
  color: #fff;
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
}


/* Login Box */

.login-box {
  background: #fff;
  padding: 40px 40px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 400px;
  text-align: center;
}


.login-box h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 10px;
}

.login-box p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

/* Input Fields */
.login-box input {
  width: 100%;
  height: 40px;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
}

.login-box input:focus {
  border-color: #007bff;
  outline: none;
}

/* Login Button */
.login-box button {
  width: 100%;
  height: 40px;
  background: #007bff;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-box button:hover {
  background: #0056b3;
}

/* Footer Styles */
.admin-footer {
  margin-top: 30px;
  text-align: center;
  color: #fff;
  font-size: 0.9rem;
}



@media (max-width: 576px) {
  form {
    padding: 20px;
  }
}

